<template>
  <header class="hideIpad" id="header-change">
    <div class="container-fluid p-0">
      <div class="row" style="max-width: 100%; margin: 0">
        <div class="col-3 logo">
          <img src="../assets/img/logo.svg" draggable="false" />
        </div>
        <div class="col cursor-link">
          <div class="col-bg"></div>
          <a href="#about"><div class="link">About</div></a>
        </div>
        <div class="col cursor-link">
          <div class="col-bg"></div>
          <a href="#about"><div class="link">Tech</div></a>
        </div>
        <div class="col cursor-link">
          <div class="col-bg"></div>
          <a href="#about"><div class="link">Stories</div></a>
        </div>
        <div class="col cursor-link">
          <div class="col-bg"></div>
          <a href="#portfolio"><div class="link">Portfolio</div></a>
        </div>
        <div class="col cursor-link">
          <div class="col-bg"></div>
          <a href="#accelerator"><div class="link">Accelerator</div></a>
        </div>

        <div class="col cursor-link">
          <div class="col-bg"></div>
          <a href="#contacts"><div class="link">contact</div></a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",

  methods: {
    Tech() {
      document.getElementById("tech").scrollIntoView();
    },
  },
};
</script>

<style></style>
