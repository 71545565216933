<template>
<div class="footer-mobile">
  <div class="hideDesktop"><a href="https://twitter.com/Impssbl1" target="_blank">TWITTER</a></div>
  <div class="hideDesktop"><a href="https://www.linkedin.com/company/impssbl/" target="_blank">LINKEDIN</a></div>
  <div class="hideDesktop"><a href="https://impssbl.medium.com/" target="_blank">MEDIUM</a></div>
  <div class="hideDesktop"><a href="https://www.instagram.com/impssblstudios/" target="_blank">INSTAGRAM</a></div>
  <div class="hideDesktop"><a href="https://discord.gg/kZ8EwJRJ9y" target="_blank">DISCORD</a></div>
</div>
</template>

<script>
export default {
  name: "FooterMobile",
};
</script>

<style>
</style>