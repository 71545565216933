<template>
  <div class="container-fluid superstudio p-0" id="superstudio">
    <div class="row p-0 m-0">
      <div class="col-md-7 col-lg-7 super-title">
        <h2 class="uppercase mb-2-5">A Y Combinator for Intellectual Properties.</h2>
        <h2 class="uppercase">We back and accelerate world-class novels, podcasts, mangas, films & games.</h2>
        <div class="lines-super">
          <svg
            data-aos="lining"
            data-aos-delay="100"
            data-aos-duration="1000"
            width="385"
            height="51"
            viewBox="0 0 385 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 15.6955C46.144 11.4173 35.9823 11.9751 95.2932 9.39138C179.605 5.71543 264.203 2.26577 348.616 2.25152C358.523 2.24973 370.005 0.788625 379.654 4.92963C387.381 8.24565 377.764 10.8454 374.447 11.3799C357.838 14.0705 319.673 16.1695 308.418 16.8448C258.043 19.8686 207.391 20.8932 157.098 25.1625C130.37 27.4308 210.703 27.2615 237.5 28.4678C254.932 29.2536 278.063 30.5098 295.556 31.9103C310.397 33.0988 326.767 33.8205 341.238 38.0506C342.655 38.464 344.326 38.692 345.308 39.795C345.73 40.2672 344.693 41.0209 344.11 41.2704C336.884 44.3565 326.321 43.6687 318.776 43.9805C301.264 44.7058 283.51 43.8273 266.051 45.4345C259.927 45.9976 264.331 47.6244 266.814 49"
              stroke="white"
              stroke-width="3"
              stroke-linecap="round"
            />
          </svg>
        </div>
      </div>
      <div class="col-md-7 col-lg-5 text-end p-0">
        <svg
          class="arrowdown"
          width="146"
          height="140"
          viewBox="0 0 146 140"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M104.568 76.2465C95.3969 82.5167 87.8114 91.241 82.0225 102.179C78.9533 107.977 76.7239 113.749 75.108 118.962V0H70.9379V0.082097H70.892V119.044C69.2765 113.831 67.0472 108.059 63.978 102.26C58.189 91.3231 50.6035 82.5983 41.4322 76.3286C29.9673 68.4902 16.0278 64.5162 0 64.5162V68.6835C15.1717 68.6835 28.3193 72.4129 39.0773 79.7683C47.6849 85.6526 54.8225 93.8756 60.292 104.209C69.6689 121.925 70.8849 139.821 70.8962 140L74.3107 139.893L75.1042 139.918C75.1155 139.739 76.3315 121.843 85.7084 104.127C91.178 93.7935 98.3155 85.5705 106.923 79.6862C117.681 72.3308 130.829 68.6014 146 68.6014V64.4341C129.973 64.4341 116.033 68.4085 104.568 76.2465Z"
            fill="white"
          />
        </svg>
      </div>
    </div>

    <div id="about" class="super-stories">
      <div class="row">
        <div class="col-lg-8 super-desc">
          <Transition enter-active-class="fade-in">
            <div v-if="this.openTab === 'stories'">
              <div class="super-desc-title">
                <h3>
                  WE DEPLOY PROPRIETARY<br /> MODELS TO PRODUCE<br />  & FIND THE NEXT-GEN<br /> OF STORIES.
                </h3>
                <img
                  src="../assets/img/superstudio_radius_icon.svg"
                  draggable="false"
                  class="rotate-center"
                />
              </div>
              <div class="desc-accordion">
                <div class="tab" @click="toggleTab1()">
                  <div class="tab-title">
                    <h3>LOOKINGLASS</h3>
                    <div class="tab-button">
                      <div
                        class="tab-button-line cursor-link"
                        :class="this.isOpen1 ? 'open' : ''"
                      ></div>
                      <div class="tab-button-line cursor-link"></div>
                    </div>
                  </div>
                  <Transition
                    enter-active-class="fade-in-top"
                    leave-active-class="fade-out-top"
                  >
                    <p class="tab-text" v-show="isOpen1">
                      A virtual data analyst that serves to monitor and scale the real-time performance of all marketing campaigns and analytics surrounding an IP (or any website).<br /> It connects Google Ads, Amazon Ads, Meta Ads, TikTok Ads etc… on a single, real-time dashboard that you can interrogate with AI, generate graphs and tables from… It’s the performance dashboard for your IPs.<br /><br />
                      Stage: launching<br />
                      Website: <a href="lookinglass.io" target="_blank">lookinglass.io</a>
                    </p>

                  </Transition>
                </div>
                <div class="tab" @click="toggleTab2()">
                  <div class="tab-title">
                    <h3>OFFSCRIPT</h3>
                    <div class="tab-button">
                      <div
                        class="tab-button-line cursor-link"
                        :class="this.isOpen2 ? 'open' : ''"
                      ></div>
                      <div class="tab-button-line cursor-link"></div>
                    </div>
                  </div>
                  <Transition
                    enter-active-class="fade-in-top"
                    leave-active-class="fade-out-top"
                  >
                    <p class="tab-text" v-show="isOpen2">
                      An AI proprietary software that 100x the production of Intellectual Properties, starting with fiction and non fiction books. The input is your style. The output? 50 novels a week, in your voice.<br />Welcome to the next age of production.<br /><br />
                      Stage: <i>in development</i>
                    </p>
                  </Transition>
                </div>
                <div class="tab" @click="toggleTab3()">
                  <div class="tab-title">
                    <h3>STORYGAP</h3>
                    <div class="tab-button">
                      <div
                        class="tab-button-line cursor-link"
                        :class="this.isOpen3 ? 'open' : ''"
                      ></div>
                      <div class="tab-button-line cursor-link"></div>
                    </div>
                  </div>
                  <Transition
                    enter-active-class="fade-in-top"
                    leave-active-class="fade-out-top"
                  >
                    <p class="tab-text" v-show="isOpen3">
                      A tool that looks for real-time trends in IPs across Amazon, TikTok, Google… to find profitable niches where to either invest in a specific IP or produce one from scratch. It inverts the push/pull dynamics of IP market demands.<br />Think JungleScout or Semrush for intellectual properties.<br /><br />
                      Stage: <i>in development</i>
                    </p>
                  </Transition>
                </div>

                <div class="tab" @click="toggleTab4()">
                  <div class="tab-title">
                    <h3>PROOF OF GENIUS</h3>
                    <div class="tab-button">
                      <div
                        class="tab-button-line cursor-link"
                        :class="this.isOpen4 ? 'open' : ''"
                      ></div>
                      <div class="tab-button-line cursor-link"></div>
                    </div>
                  </div>
                  <Transition
                    enter-active-class="fade-in-top"
                    leave-active-class="fade-out-top"
                  >
                    <p class="tab-text" v-show="isOpen4">
                      A magnifying glass looking for genius-level IPs around the world. Based on a proprietary scouting-algorithm. <br />It looks for: significant delta in IPs’ community growth; great interactions between community and IP; intrinsic quality of an IP matched against the best-in-class of their format/genre combination.<br /><br />
                      Stage: <i>in development</i>
                    </p>
                  </Transition>
                </div>
              </div>
            </div>
          </Transition>
          <Transition enter-active-class="fade-in">
            <div v-if="this.openTab === 'studio'">
              <div class="super-desc-title studio">
                <h3 class="bigger-title">
                  AN ACCELERATOR FOR WORLD-CLASS STORIES.
                </h3>
                <img
                  src="../assets/img/superstudio_radius_icon.svg"
                  draggable="false"
                  class="rotate-center"
                />
              </div>
              <div class="tab-text">
                <p>
                  We are an accelerator that doesn’t invest in companies. We back and accelerate world-class intellectual properties: from novels to films, from podcasts to games.<br />
                  And we do that with a hell-lot-of tech.
                </p>
                <p class="accent">
                  The next Game of Thrones has already been written.<br />We simply don’t know where and when.
                </p>
                <svg data-aos="lining" data-aos-delay="100" data-aos-duration="1000" width="385" height="51" viewBox="0 0 385 51" fill="none" xmlns="http://www.w3.org/2000/svg" class="aos-init aos-animate"><path d="M2 15.6955C46.144 11.4173 35.9823 11.9751 95.2932 9.39138C179.605 5.71543 264.203 2.26577 348.616 2.25152C358.523 2.24973 370.005 0.788625 379.654 4.92963C387.381 8.24565 377.764 10.8454 374.447 11.3799C357.838 14.0705 319.673 16.1695 308.418 16.8448C258.043 19.8686 207.391 20.8932 157.098 25.1625C130.37 27.4308 210.703 27.2615 237.5 28.4678C254.932 29.2536 278.063 30.5098 295.556 31.9103C310.397 33.0988 326.767 33.8205 341.238 38.0506C342.655 38.464 344.326 38.692 345.308 39.795C345.73 40.2672 344.693 41.0209 344.11 41.2704C336.884 44.3565 326.321 43.6687 318.776 43.9805C301.264 44.7058 283.51 43.8273 266.051 45.4345C259.927 45.9976 264.331 47.6244 266.814 49" stroke="white" stroke-width="3" stroke-linecap="round"></path></svg>
              </div>
            </div>
          </Transition>
          <Transition enter-active-class="fade-in">
            <div v-if="this.openTab === 'thesis'">
              <div class="super-desc-title studio">
                <h3>
                  WE MAKE AND WE BUY WORLD-CLASS IPs. THEN WE SCALE THEM UP.
                </h3>
                <img
                  src="../assets/img/superstudio_radius_icon.svg"
                  draggable="false"
                  class="rotate-center"
                />
              </div>
              <div class="tab-text">
                <p>
                  We have two main divisions within the IP part of the company: one making and the other buying stories.<br /><br />
                </p>
                <p>
                  <strong class="italic">ORIGINALS.</strong> We usually produce a limited number of original, proprietary stories throughout the year: from novels to podcasts, to web3 storytelling projects. What they have in common is their <i>impossibility</i>: they are stories that, until a few years back, were impossible to make.<br /><br />
                </p>
                <p>
                  <strong class="italic">NON ORIGINALS.</strong> We buy seed-stage stories of different formats (book, audio-book, podcast, graphic novel, manga, webtoon, app, game, film, series, VR & AR) as long as they already have an audience (and possibly, they show already some level of monetisation) and as long as they’re true creative outliers standing out from everything else.
                </p>
              </div>
            </div>
          </Transition>
        </div>

        <div class="super-buttons layers-anim cursor-link">
          <div
            class="super-button movie cursor-link"
            @click="changeOpenTab('studio')"
          >
            <!-- @click="studioTxt()" -->
            <h3>about</h3>
            <div
              class="movie-1 effect-movie"
              :class="{ visible: this.openTab === 'studio' }"
            ></div>
            <div
              class="movie-2 effect-movie"
              :class="{ visible: this.openTab === 'studio' }"
            >
              <h3>about</h3>
            </div>
          </div>
          <div
            class="super-button movie cursor-link"
            @click="changeOpenTab('stories')"
          >
            <!-- @click="storiesTxt()" -->
            <h3>our tech</h3>
            <div
              class="movie-1 effect-movie"
              :class="{ visible: this.openTab === 'stories' }"
            ></div>
            <div
              class="movie-2 effect-movie"
              :class="{ visible: this.openTab === 'stories' }"
            >
              <h3>our tech</h3>
            </div>
          </div>
          <div
            class="super-button movie cursor-link"
            @click="changeOpenTab('thesis')"
          >
            <!--  @click="thesisTxt()" -->
            <h3>our stories</h3>
            <div
              class="movie-1 effect-movie"
              :class="{ visible: this.openTab === 'thesis' }"
            ></div>
            <div
              class="movie-2 effect-movie"
              :class="{ visible: this.openTab === 'thesis' }"
            >
              <h3>our stories</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="prism-container">
    <img
      src="../assets/img/blob-clone.png"
      draggable="false"
      class="blob-clone"
    />
  </div>
  <div id="portfolio"></div>
</template>

<script>
import checkVieport from "@/mixins/checkViewport";
export default {
  name: "Superstudio",
  mixins: [checkVieport],

  data() {
    return {
      isOpen1: false,
      isOpen2: false,
      isOpen3: false,
      isOpen4: false,
      openTab: "studio",
    };
  },

  methods: {
    toggleTab1() {
      this.isOpen1 = !this.isOpen1;
      if (this.isOpen1) {
        this.isOpen2 = false;
        this.isOpen3 = false;
        this.isOpen4 = false;
      }
    },
    toggleTab2() {
      this.isOpen2 = !this.isOpen2;
      if (this.isOpen2) {
        this.isOpen1 = false;
        this.isOpen3 = false;
        this.isOpen4 = false;
      }
    },
    toggleTab3() {
      this.isOpen3 = !this.isOpen3;
      if (this.isOpen3) {
        this.isOpen1 = false;
        this.isOpen2 = false;
        this.isOpen4 = false;
      }
    },

    toggleTab4() {
      this.isOpen4 = !this.isOpen4;
      if (this.isOpen4) {
        this.isOpen1 = false;
        this.isOpen2 = false;
        this.isOpen3 = false;
      }
    },

    changeOpenTab(newTab) {
      this.openTab = newTab;
    },
  },
};
</script>

<style>
#projects {
  height: 3rem;
}

.superstudio {

  & .super-title {
    margin-top: 3.5rem;
  }
}

.superstudio .super-desc .bigger-title {
  font-size: 3rem;
  line-height: 1.2;

  @media screen and (max-width: 1023px) {
    font-size: 2rem;
  }
}
</style>
