<template>
  <HeaderMobile />
  <div v-if="!isMobile && !isTablet" class="cursor"></div>
  <div class="container-fluid p-0 bord-top content_cursor">
    <div class="row page-wrapper">
      <div class="col-1 p-0 col-resp m-0"></div>
      <div class="col-10 col-10-resp p-0">
        <Header class="hideIpad" />
        <div class="home line-sx-w">
          <Cover />
          <Superstudio />

          <Projects />
        </div>
      </div>
      <div class="col-1 p-0 prova col-resp">
        <div class="prova-wrapper hideIpad">

          <marquee-text :repeat="50" class="proof-marquee">
            <p>
              IMPSSBL&nbsp; IMPSSBL&nbsp; IMPSSBL&nbsp;
              IMPSSBL&nbsp; IMPSSBL&nbsp; IMPSSBL&nbsp;
              IMPSSBL&nbsp; IMPSSBL&nbsp; IMPSSBL&nbsp;
              IMPSSBL&nbsp; IMPSSBL&nbsp; IMPSSBL&nbsp;
            </p>
          </marquee-text>

        </div>
      </div>
    </div>
    <div class="row white-bg" id="white">
      <div class="col-1 p-0 col-resp"></div>
      <div class="col-10 p-0 line-sx-b">
        <Tech />
        <DAO />
      </div>
      <div class="col-1 p-0 prova col-resp"></div>
    </div>
    <div class="row black-bg" id="black">
      <div class="col-1 p-0 col-resp"></div>
      <div class="col-10 col-10-resp p-0">
        <Studio />
        <Contacts />
        <FooterMobile />
      </div>

      <div class="col-1 p-0 prova col-resp"></div>
    </div>
    <Footer />

    <div class="row text-center justify-center copy">
      <img src="../assets/img/logo.svg" draggable="false" />
      <p>Copyright ©{{ getYear() }} Impssbl Corp.</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import HeaderMobile from "@/components/HeaderMobile.vue";
import Cover from "@/components/Cover.vue";
import Superstudio from "@/components/Superstudio.vue";
import Projects from "@/components/Projects.vue";
import Tech from "@/components/Tech.vue";
import DAO from "@/components/DAO.vue";
import Studio from "@/components/Studio.vue";
import Contacts from "@/components/Contacts.vue";
import Footer from "@/components/Footer.vue";
import checkViewport from "@/mixins/checkViewport";
import MarqueeText from "vue-marquee-text-component";
import FooterMobile from "../components/FooterMobile.vue";

export default {
  name: "Home",
  mixins: [checkViewport],
  components: {
    Header,
    HeaderMobile,
    Cover,
    Superstudio,
    Projects,
    Tech,
    DAO,
    Studio,
    Contacts,
    Footer,

    MarqueeText,
    FooterMobile,
  },

  mounted() {
    this.test();
    this.cursorCustom();
  },

  methods: {
    test() {
      const app = this;
      app.box = document.getElementById("white");
      app.boxblack = document.getElementById("black");
      app.svgMob = document.getElementById("open");
      document.addEventListener("scroll", () => {
        /* console.log(window.scrollY, app.box); */

        if (
          window.scrollY >= app.box.offsetTop &&
          window.scrollY <= app.boxblack.offsetTop
        ) {
          document.getElementById("header-change").classList.add("nav-white");
          document.getElementById("header-mobile").classList.add("nav-white");
          app.svgMob.classList.add("black-svg");
        } else {
          document
            .getElementById("header-change")
            .classList.remove("nav-white");
          app.svgMob.classList.remove("black-svg");
          document
            .getElementById("header-mobile")
            .classList.remove("nav-white");
        }
      });
    },

    getYear() {
      const year = new Date().getFullYear()
      return year
    },

    cursorCustom() {
      var $cursor = window.$(".cursor");

      function moveCursor(e) {
        // $cursor.addClass("is-moving");
        $cursor.css({ top: e.pageY, left: e.pageX });
        // clearTimeout(timer2);
        // var timer2 = setTimeout(function () {
        //   $cursor.removeClass("is-moving");
        // }, 300);
      }
      window.$(window).on("mousemove", moveCursor);
      window.$(".cursor-link").on("mouseenter", function () {
        $cursor.addClass("is-moving");
      });
      window.$(".cursor-link").on("mouseleave", function () {
        $cursor.removeClass("is-moving");
      });
    },
  },
};
</script>

<style scoped>
.cursor {
  position: absolute;
  width: 60px;
  height: 60px;
  top: 50%;
  left: 50%;
  transform: translate(-28%, -25%);
  margin: -15px 0 0 -15px;
  border-radius: 50%;
  /* background-color: white; */
  border: 3px solid white;
  backface-visibility: hidden;
  transition: transform 0.2s ease-out;
  mix-blend-mode: difference;
  z-index: 9999;
  pointer-events: none;
}
.cursor.is-moving {
  transform: scale(1.2) !important;
  transition: transform 0.2s ease-out;
}

.content_cursor {
  position: relative;
  height: 100%;
}
</style>
