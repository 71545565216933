<template>
  <div class="contacts" id="contacts">
    <div class="container-fluid line-xs">
      <div class="row">
        <div class="projects-title">
          <svg
            width="146"
            height="140"
            viewBox="0 0 105 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M74.6916 54.4618C68.1406 58.9405 62.7224 65.1722 58.5875 72.9848C56.3952 77.1265 54.8028 81.249 53.6486 84.9731V0H50.6699V0.0586407H50.6371V85.0317C49.4832 81.3076 47.8908 77.1849 45.6985 73.0431C41.5636 65.2308 36.1454 58.9988 29.5944 54.5204C21.4052 48.9215 11.4485 46.083 0 46.083V49.0597C10.8369 49.0597 20.2281 51.7235 27.9124 56.9774C34.0606 61.1804 39.1589 67.054 43.0657 74.435C49.7635 87.0895 50.6321 99.8723 50.6401 100L53.0791 99.9238L53.6459 99.9414C53.6539 99.8137 54.5225 87.0309 61.2203 74.3764C65.1271 66.9954 70.2254 61.1218 76.3733 56.9187C84.058 51.6649 93.4491 49.001 104.286 49.001V46.0243C92.8376 46.0243 82.8808 48.8632 74.6916 54.4618Z"
              fill="white"
            />
          </svg>
          <div>
            <h2>
              IT JUST TAKES ONE<br>
              <span>STORY.</span><br />
            </h2>
          </div>
          <img src="../assets/img/BLOB_radial.png" draggable="false" />
        </div>
      </div>
      <div class="row address">
        <h2>
          If you're: <i class="thin">early, a genius, a visionary</i>,<br /> text us: <a
            class="cursor-link"
            href="mailto:info@impssbl.com"
            >info@impssbl.com</a
          ><br /><br />
        </h2>
        <h2>
          ADDRESS:<br />925 N La Brea Ave, 90038 Los Angeles USA //<br /><br /> Corso XXII Marzo, 4 Milano 20135 Italy
        </h2>
      </div>

      <div class="row">
        <div class="projects-title sayhello">
          <h2>
            SAY HELLO.<br />
          </h2>

          <svg
            width="479"
            height="244"
            viewBox="0 0 479 244"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.7958 2.00007C74.8262 42.0645 155.201 106.967 211.311 144.912C234.44 160.554 270.703 183.768 301.312 189.175C321.475 192.737 349.516 180.57 345.509 156.399C341.838 134.252 318.017 117.629 301.074 106.034C284.728 94.8475 266.523 84.4067 247.693 77.9545C244.213 76.7624 238.862 73.54 236.746 76.5424C233.988 80.456 240.216 85.627 243.193 89.3817C263.116 114.516 288.081 136.816 313.344 156.414C355.144 188.84 401.853 215.25 454.682 222.022"
              stroke="white"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M455.752 222.807C439.526 226.145 423.306 229.516 407.093 232.919M456.849 222.47C444.416 212.295 432.044 202.046 419.732 191.726L456.849 222.47Z"
              stroke="white"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <img
            class="sayhello-blob"
            src="../assets/img/blob_sayhello.png"
            draggable="false"
          />
        </div>
      </div>

      <div class="row email">
        <div class="col-lg-6">
          <h3>
            ENTER YOUR EMAIL TO BE NOTIFIED.
            <br />
            THE STORY REVOLUTION STARTS HERE.
          </h3>

          <!-- <input
            class="cursor-link"
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email address"
          /> -->
          <div id="mc_embed_signup">
            <form
              action="https://proofofstory.us5.list-manage.com/subscribe/post?u=0910fce3a51510bfc3bcc8ddb&amp;id=4bd091b827"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              class="validate"
              target="_blank"
              novalidate
            >
              <div id="mc_embed_signup_scroll">
                <div class="mc-field-group">
                  <input
                    type="email"
                    value=""
                    name="EMAIL"
                    class="required email"
                    id="mce-EMAIL"
                    placeholder="Enter your email address"
                  />
                </div>
                <div class="clear">
                  <input
                    type="submit"
                    value="+"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    class="button"
                  />
                  <!-- <svg
                    class="plus-btn cursor-link"
                    width="28"
                    height="28"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    type="submit"
                    value="Subscribe"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                  >
                    <path
                      d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
                      fill="white"
                    />
                    <path
                      d="M11.0107 2.35712V20.4285"
                      stroke="black"
                      stroke-width="1.3"
                      stroke-linecap="square"
                    />
                    <path
                      d="M19.6428 11.3929L1.57139 11.3929"
                      stroke="black"
                      stroke-width="1.3"
                      stroke-linecap="square"
                    />
                  </svg> -->
                </div>
              </div>
              <div id="mce-responses" class="clear">
                <div
                  class="response"
                  id="mce-error-response"
                  style="display: none"
                ></div>
                <div
                  class="response"
                  id="mce-success-response"
                  style="display: none"
                ></div>
              </div>
              <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
              <div style="position: absolute; left: -5000px" aria-hidden="true">
                <input
                  type="text"
                  name="b_0910fce3a51510bfc3bcc8ddb_4bd091b827"
                  tabindex="-1"
                  value=""
                />
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-6 text-end">
          <svg
            @click="backToTop()"
            id="back-arrow"
            class="arrow cursor-link"
            width="146"
            height="140"
            viewBox="0 0 105 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M74.6916 54.4618C68.1406 58.9405 62.7224 65.1722 58.5875 72.9848C56.3952 77.1265 54.8028 81.249 53.6486 84.9731V0H50.6699V0.0586407H50.6371V85.0317C49.4832 81.3076 47.8908 77.1849 45.6985 73.0431C41.5636 65.2308 36.1454 58.9988 29.5944 54.5204C21.4052 48.9215 11.4485 46.083 0 46.083V49.0597C10.8369 49.0597 20.2281 51.7235 27.9124 56.9774C34.0606 61.1804 39.1589 67.054 43.0657 74.435C49.7635 87.0895 50.6321 99.8723 50.6401 100L53.0791 99.9238L53.6459 99.9414C53.6539 99.8137 54.5225 87.0309 61.2203 74.3764C65.1271 66.9954 70.2254 61.1218 76.3733 56.9187C84.058 51.6649 93.4491 49.001 104.286 49.001V46.0243C92.8376 46.0243 82.8808 48.8632 74.6916 54.4618Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contacts",

  data() {
    return {};
  },

  methods: {
    backToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss">
#mc_embed_signup form {
  padding: 0 !important;
}

#mc_embed_signup .mc-field-group input {
  background-color: black;
  border: none;
  border-bottom: 2px solid white;
  color: white;
  font-size: 1.5rem !important;
  width: 92% !important;
  margin-top: 3rem;
  padding: 1rem 0;
}
#mc_embed_signup .clear .button {
  position: absolute;
  right: -15px;
  bottom: 1.5rem;
  background-color: white;
  color: black;
  width: 28px;
  height: 28px;
  padding: 0;
  margin: 0;
  border-radius: 50px;
  transition: ease-in-out 0.2s;
  line-height: 0;
  font-size: 2rem;
  z-index: 10;
  box-sizing: border-box;
  vertical-align: middle;
  &:hover {
    transform: rotate(45deg);
  }
}
</style>
