<template>
  <div class="dao-section" id="b2b">
    <div class="container-fluid">

      <div class="collabs" >
        <div class="projects-title">
          <h2>
            CLIENTS.
          </h2>
          <div class="clients-sign">
            <svg
              width="259"
              height="305"
              viewBox="0 0 259 305"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M68.3123 302.841C59.8869 298.486 49.846 293.205 41.4508 288.796L68.7328 270.773"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M253.667 37.7207C282.316 269.791 114.947 271.271 58.7645 233.283C-17.7507 181.549 -7.0671 95.2329 34.0027 46.9835C43.621 35.684 58.1466 23.9815 72.9532 16.7988C95.2866 5.96533 125.302 -2.21867 155.478 4.3492C206.827 15.5268 228.1 65.8088 232.731 99.3218C240.647 156.589 215.928 207.213 171.328 244.624C152.525 260.395 136.93 270.077 112.173 279.536C104.684 282.399 96.6258 284.571 88.3747 286.319C75.8248 288.978 65.3913 289.185 55.0073 288.827"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>

      <div class="row squares justify-content-center">
        <div class="container-fluid line-xs clients-container"  >

          <div class="row squares m-0 justify-content-center max-width-row">

            <div class="col-lg-2 layers-anim">
              <div class="movie" >
                <img src="icon.png"  style="width: 160px; height: 160px; object-fit: contain;" />
                <div class="movie-1 effect-movie" ></div>
                <div class="movie-2 effect-movie" >
                  <img src="icon.png"   style="width: 160px; height: 160px; filter: invert(1); object-fit: contain;">
                </div>
              </div>
            </div>

            <div class="col-lg-2 layers-anim" >
              <div class="movie">
                <img src="oniro.png" style="width: 160px; height: 160px; object-fit: contain;" />
                <div class="movie-1 effect-movie"></div>
                <div class="movie-2 effect-movie">
                  <img src="oniro.png" style="width: 160px; height: 160px; filter: invert(1); object-fit: contain;">
                </div>
              </div>
            </div>

            <div class="col-lg-2 layers-anim">
              <div class="movie">
                <img src="icon.png"  style="width: 160px; height: 160px; object-fit: contain;" />
                <div class="movie-1 effect-movie"></div>
                <div class="movie-2 effect-movie" >
                  <img src="icon.png"  style="width: 160px; height: 160px; filter: invert(1); object-fit: contain;">
                </div>
              </div>
            </div>

            <div class="col-lg-2 layers-anim" >
              <div class="movie" >
                <img src="skinlabo.png"  style="width: 160px; height: 160px; object-fit: contain;" />
                <div class="movie-1 effect-movie" ></div>
                <div class="movie-2 effect-movie" >
                  <img src="skinlabo.png" style="width: 160px; height: 160px; filter: invert(1); object-fit: contain;">
                </div>
              </div>
            </div>

          </div>
        </div>

        <svg
          class="prisma-collabs"
          width="914"
          height="841"
          viewBox="0 0 914 841"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M346.258 497.132L479.28 107.921"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M351.374 59.649L362.157 50.9909L766.77 159.407"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M613.433 480.434L409.419 586.984L284.922 553.626L149.712 305.879L175.575 230.2L228.717 202.445L281.853 174.694L334.99 146.942L443.246 90.405L567.742 123.764L670.795 312.588L702.953 371.51L677.085 447.188L613.433 480.434Z"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M157.293 215.522L419.218 78.7248L626.714 134.323L759.024 376.755L760.365 403.585L761.707 430.414L762.299 442.255L762.709 450.516L743.245 474.057L713.454 510.092L451.528 646.889L244.033 591.29L111.718 348.857"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M178.853 198.204L413.213 75.8039L641.455 136.961L759.838 353.879L761.18 380.708L762.521 407.538L763.862 434.367L764.557 448.284L764.972 456.546L755.998 467.395L726.201 503.432L696.41 539.466L462.051 661.862L233.809 600.705L115.425 383.792"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M621.945 455.514L398.891 572.009L295.145 544.211L209.312 386.938L182.468 337.756L226.197 209.817L279.333 182.066L332.47 154.314L385.606 126.563L438.744 98.8068L449.252 93.3211L552.997 121.12L601.362 209.74L633.521 268.658L665.675 327.575L621.945 455.514Z"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M200.42 180.884L407.207 72.8878L656.2 139.605L760.654 330.998L761.995 357.827L763.337 384.657L764.678 411.486L766.019 438.316L766.406 446.047L766.821 454.309L767.231 462.57L738.952 496.774L709.162 532.808L679.366 568.841L472.579 676.837L223.586 610.119L119.132 418.727"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M221.987 163.563L401.196 69.9656L670.94 142.243L761.47 308.117L762.811 334.946L764.151 361.781L765.492 388.61L766.834 415.439L768.175 442.269L768.664 452.075L769.079 460.338L769.494 468.6L751.705 490.112L721.908 526.149L692.117 562.183L662.322 598.216L483.106 691.817L213.366 619.54L122.834 453.661"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M566.808 463.834L388.364 557.03L305.37 534.791L215.226 369.628L276.814 189.438L329.95 161.686L383.086 133.935L436.222 106.184L455.257 96.2421L538.256 118.482L596.242 224.727L628.396 283.644L566.808 463.834Z"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M243.552 146.247L395.191 67.0446L685.686 144.883L762.284 285.24L763.626 312.07L764.967 338.899L766.308 365.729L767.65 392.558L768.991 419.388L770.332 446.217L771.337 466.366L771.753 474.629L764.457 483.454L704.864 555.524L675.073 591.558L645.277 627.591L493.634 706.792L203.143 628.955L126.54 488.596"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M511.674 472.161L377.841 542.056L315.588 525.376L247.982 401.505L327.43 169.059L380.566 141.307L433.703 113.556L461.263 99.163L523.511 115.842L558.963 180.797L591.117 239.713L511.674 472.161Z"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M419.946 601.959L274.699 563.04L143.793 323.188L116.954 274.007L124.958 250.579L178.094 222.828L437.235 87.4828L582.483 126.402L708.074 356.518L740.231 415.441L732.222 438.868L668.565 472.112L419.946 601.959Z"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M265.114 128.924L389.185 64.1285L700.426 147.525L763.1 262.359L764.442 289.189L765.783 316.018L767.123 342.852L768.464 369.682L769.806 396.511L771.536 431.083L772.488 450.17L773.185 464.134L773.601 472.396L774.011 480.657L717.617 548.861L687.82 584.899L658.029 620.933L628.232 656.971L504.161 721.766L192.92 638.37L130.246 523.536"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M286.68 111.608L383.179 61.2076L715.172 150.165L763.915 239.483L765.256 266.312L766.597 293.142L767.939 319.971L769.28 346.801L770.621 373.63L771.962 400.46L773.383 428.851L774.645 454.119L775.443 470.163L775.859 478.425L776.274 486.687L700.571 578.241L670.775 614.274L640.985 650.308L611.188 686.345L514.689 736.741L182.697 647.784L133.948 558.469"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M456.536 480.481L367.313 527.081L325.811 515.961L307.582 482.564L280.745 433.378L378.046 148.679L431.183 120.928L467.269 102.079L508.771 113.2L521.684 136.866L553.838 195.782L456.536 480.481Z"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M308.246 94.2873L377.174 58.2867L729.913 152.803L764.73 216.602L766.072 243.431L767.413 270.261L768.753 297.095L770.094 323.925L771.436 350.754L772.777 377.583L774.118 404.413L776.801 458.072L777.708 476.188L778.117 484.454L778.532 492.716L683.527 607.616L653.731 643.649L623.934 679.686L594.143 715.72L525.216 751.721L172.472 657.204L137.655 593.405"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M401.397 488.806L356.786 512.106L336.035 506.546L313.501 465.255L428.663 128.3L473.274 105L494.025 110.56L516.559 151.852L401.397 488.806Z"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M805.671 526.225L807.176 545.689L807.087 554.557L807.502 562.819L807.912 571.08L777.96 607.31"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M790.854 386.487L796.398 497.394L797.223 513.918L798.048 530.446L798.458 538.707L798.873 546.969L691.7 676.585"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M794.555 421.421L798.246 495.162L799.482 519.946L800.307 536.47L800.722 544.732L801.131 552.998L713.267 659.264"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M798.262 456.356L800.09 492.924L801.33 517.714L802.57 542.5L802.981 550.761L803.396 559.023L734.829 641.942"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M329.807 76.9699L351.374 59.649L365.158 52.4483L759.398 158.085L766.361 170.844L767.702 197.674L769.043 224.503L771.726 278.162L773.066 304.997L781.114 465.973L781.814 479.985L782.229 488.247L782.64 496.508L783.055 504.77L679.234 630.333L649.438 666.365L619.641 702.403L589.846 738.436L560.055 774.47L546.266 781.669L152.031 676.034L145.063 663.273L141.361 628.34L137.655 593.405L133.948 558.47L130.246 523.536L126.54 488.596L122.834 453.661L119.132 418.727L115.425 383.792L111.718 348.857L108.016 313.923L106.901 303.415L104.309 278.988L102.266 259.712L114.16 250.159L129.244 238.049L135.727 232.838L157.292 215.522L178.853 198.204L200.42 180.884L221.987 163.563L243.552 146.247L265.114 128.924L286.68 111.608L308.246 94.2873L329.807 76.9699ZM329.807 76.9699L371.168 55.3706L744.652 155.445L765.545 193.725L766.886 220.555L768.227 247.384L769.569 274.214L770.91 301.043L772.251 327.873L773.593 354.702L774.934 381.532L776.274 408.366L778.957 462.025L779.556 473.956L779.966 482.217L780.381 490.479L780.792 498.74L666.483 636.991L636.687 673.023L606.89 709.061L577.099 745.095L535.739 766.695L162.249 666.618L141.361 628.34"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M135.727 232.838L183.135 208.078L425.224 81.6458L611.968 131.684L758.209 399.632L759.549 426.466L760.04 436.226L760.451 444.487L760.291 444.678L730.499 480.717L441.001 631.914L254.256 581.876L108.016 313.923"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M801.969 491.291L803.179 515.477L804.413 540.266L804.829 548.528L805.239 556.789L805.654 565.052L756.395 624.626"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M776.032 246.743L780.877 343.676L782.218 370.505L786.118 448.497L789.01 506.329L789.42 514.59L789.834 522.858L605.441 745.86"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M772.326 211.808L776.037 286.069L777.379 312.898L778.72 339.728L780.061 366.557L781.403 393.386L784.27 450.73L785.506 475.514L786.335 492.043L786.746 500.304L787.161 508.567L787.571 516.828L583.88 763.178"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M768.624 176.874L769.858 201.627L771.199 228.456L772.54 255.286L773.882 282.115L775.223 308.945L776.564 335.774L777.906 362.604L779.247 389.433L783.27 469.927L784.072 486.013L784.487 494.276L784.903 502.538L785.313 510.799L662.19 659.707L602.598 731.773L562.313 780.499"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M114.16 250.159L127.478 243.207L180.615 215.451L431.23 84.5619L597.229 129.041L745.353 400.449L757.393 422.513L758.192 438.459L747.543 451.342L430.474 616.934L264.48 572.456L104.309 278.988"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M766.77 159.407L768.375 174.531L768.624 176.874L771.585 204.781L772.326 211.808L774.788 235.034L776.032 246.743L779.739 281.678L783.441 316.612L787.147 351.552L790.854 386.487L794.555 421.421L798.262 456.356L801.969 491.291L805.671 526.225L807.176 545.689L809.32 560.621L809.378 561.16L809.76 568.848L810.573 576.631L811.421 580.436L799.527 589.989L777.96 607.31L756.395 624.626L734.829 641.942L713.267 659.264L691.7 676.585L670.135 693.901L648.573 711.223L627.007 728.539L605.441 745.86L583.88 763.178L562.313 780.499L551.53 789.157L146.917 680.741"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M779.739 281.678L787.962 446.259L790.853 504.096L791.268 512.358L791.683 520.62L792.094 528.881L627.007 728.539"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M783.441 316.612L789.81 444.027L792.701 501.864L793.526 518.387L793.942 526.649L794.352 534.91L648.573 711.223"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M787.147 351.552L791.658 441.795L794.55 499.627L795.374 516.155L795.789 524.417L796.2 532.678L796.615 540.94L670.135 693.901"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DAO",
};
</script>

<style>

  .block-p {
    & a {
      color: black;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }

</style>