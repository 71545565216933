<template>
  <div class="container-fluid cover">
    <div class="row">
      <div class="col-md-10 col-lg-12">
        <h2>
          <span class="normal">WE</span><br />
          <span class="italic">ACCELERATE</span>
          <a
            class="special-title hideIpadxx cursor-link"
            @mouseover="follow()"
            @mouseleave="follow()"

            href="#projects"
            id="hoverLink"
          >
            STORIES
            <!-- <img
              id="hoverimage"
              class="img-proof"
              src="../assets/img/project_proof.png"
              draggable="false"
            /> -->
            <Transition
              enter-active-class="animate__animated animate__fadeIn"
              leave-active-class="animate__animated animate__fadeOut"
            >
              <div v-if="isFollow" class="img-line-cover">
                <svg
                  class="line"
                  width="721px"
                  height="633px"
                  viewBox="0 0 721 633"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <title>DRAW</title>
                  <g
                    id="IMPSSBL_HP"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                    stroke-linecap="round"
                  >
                    <g
                      id="IMPSSBL_DESK_1"
                      transform="translate(-131.000000, -445.000000)"
                      stroke="#FFFFFF"
                      stroke-width="3"
                    >
                      <g
                        id="COVER"
                        transform="translate(113.000000, 46.000000)"
                      >
                        <g
                          id="TITLE"
                          transform="translate(0.000000, 77.000000)"
                        >
                          <g
                            id="DRAW"
                            transform="translate(20.000000, 324.000000)"
                          >
                            <path
                              d="M180.055331,618.495713 C194.06678,606.58598 212.281665,591.173386 228.394832,579.330908 L170.947888,543.534456"
                              id="Path"
                              stroke-linejoin="round"
                              transform="translate(199.671360, 581.015085) rotate(146.303258) translate(-199.671360, -581.015085) "
                            ></path>
                            <path
                              d="M653.679473,0 C752.780491,42.9654574 718.936878,156.328466 664.656424,218.985958 C582.601998,313.705007 450.808482,377.063083 330.172787,404.066419 C241.524339,423.908379 100.742034,447.246256 19.0100882,394.090093 C-42.6425478,353.994231 62.9277065,312.467774 91.5401267,301.441973 C181.471486,266.789653 320.374992,240.648036 406.547352,299.232328 C551.127915,397.522958 237.508757,546.170214 179.692236,596"
                              id="Path"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </Transition>
          </a>
          <!-- <a class="special-title hideDesktopSpecialTitle"> STORIES </a> -->
          <!-- <div style="position: relative">
            YEARS.
            <img
              v-if="isMobile"
              class="img-line-mobile"
              src="../assets/img/combinedShape.svg"
            />
          </div> -->
        </h2>
      </div>
    </div>
    <div class="blob-intro"></div>
  </div>
</template>

<script>
import checkVieport from "@/mixins/checkViewport";
export default {
  name: "Cover",
  mixins: [checkVieport],
  data() {
    return {
      isFollow: false,
      paused: null,
      videoElement: null,
    };
  },
  methods: {
    specialLink() {
      document.getElementById("stories").scrollIntoView();
    },

    // mouseOver: function (event) {
    //   const app = this;
    //   app.isFollow = !app.isFollow;
    //   if (app.isFollow) {
    //     var x = event.pageX;
    //     // var y = event.pageY;
    //     event.target.querySelector(
    //       ".img-proof"
    //     ).style.transform = `translate(${x}px, 0)`;
    //   }
    // },

    follow() {
      const app = this;
      setTimeout(function () {
        app.isFollow = !app.isFollow;
        if (app.isFollow) {
          window.$(document).mousemove(function (e) {
            window.$("#hoverimage").css({ left: e.pageX, top: e.pageY });
          });
        } else {
          window.$(document).off("mousemove");
        }
      }, 200);
    },

    updatePaused(event) {
      const app = this;
      app.videoElement = event.target;
      app.paused = event.target.paused;
    },
    play() {
      const app = this;
      app.videoElement.play();
    },
    pause() {
      const app = this;
      app.videoElement.pause();
    },
  },
  computed: {
    playing() {
      return !this.paused;
    },
  },
};
</script>

<style></style>
