<template>
  <div class="studio">
    <div class="container-fluid line-xs">
      <div class="row squares m-0 justify-content-center max-width-row">
        <div class="container-fluid line-xs"  style="margin-top: 0px;">
          <div class="row squares m-0 justify-content-center max-width-row">

            <div class="col-lg-2 layers-anim" >
              <div class="movie">
                <img src="62ea68681ff8d7.47323899.png" style="width: 160px; height: 160px; object-fit: contain;" />
                <div class="movie-1 effect-movie"></div>
                <div class="movie-2 effect-movie">
                  <img src="62ea68681ff8d7.47323899.png" style="width: 160px; height: 160px; filter: invert(1); object-fit: contain;">
                </div>
              </div>
            </div>

            <div class="col-lg-2 layers-anim">
              <div class="movie" >
                <img src="icon.png"  style="width: 160px; height: 160px; object-fit: contain;" />
                <div class="movie-1 effect-movie" ></div>
                <div class="movie-2 effect-movie" >
                  <img src="icon.png"   style="width: 160px; height: 160px; filter: invert(1); object-fit: contain;">
                </div>
              </div>
            </div>

            <div class="col-lg-2 layers-anim" >
              <div class="movie">
                <img src="62ea68692c0fa7.62418237.png" style="width: 160px; height: 160px; object-fit: contain;" />
                <div class="movie-1 effect-movie" ></div>
                <div class="movie-2 effect-movie" >
                  <img src="62ea68692c0fa7.62418237.png" style="width: 160px; height: 160px; filter: invert(1); object-fit: contain;">
                </div>
              </div>
            </div>

            <div class="col-lg-2 layers-anim">
              <div class="movie">
                <img src="icon.png"  style="width: 160px; height: 160px; object-fit: contain;" />
                <div class="movie-1 effect-movie"></div>
                <div class="movie-2 effect-movie" >
                  <img src="icon.png"  style="width: 160px; height: 160px; filter: invert(1); object-fit: contain;">
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="row" id="team">
        <div class="projects-title">
          <h2>
            WE ARE CREATIVE TECHNOLOGISTS & TECHNOLOGICAL CREATIVES.
          </h2>
          <svg
            width="146"
            height="140"
            viewBox="0 0 105 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M74.6916 54.4618C68.1406 58.9405 62.7224 65.1722 58.5875 72.9848C56.3952 77.1265 54.8028 81.249 53.6486 84.9731V0H50.6699V0.0586407H50.6371V85.0317C49.4832 81.3076 47.8908 77.1849 45.6985 73.0431C41.5636 65.2308 36.1454 58.9988 29.5944 54.5204C21.4052 48.9215 11.4485 46.083 0 46.083V49.0597C10.8369 49.0597 20.2281 51.7235 27.9124 56.9774C34.0606 61.1804 39.1589 67.054 43.0657 74.435C49.7635 87.0895 50.6321 99.8723 50.6401 100L53.0791 99.9238L53.6459 99.9414C53.6539 99.8137 54.5225 87.0309 61.2203 74.3764C65.1271 66.9954 70.2254 61.1218 76.3733 56.9187C84.058 51.6649 93.4491 49.001 104.286 49.001V46.0243C92.8376 46.0243 82.8808 48.8632 74.6916 54.4618Z"
              fill="white"
            />
          </svg>

          <svg
            width="539"
            height="276"
            viewBox="0 0 539 276"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M80.7371 45.9792C134.765 51.0045 265.87 68.4578 358.06 98.0949C473.296 135.141 530.171 201.535 310.673 192.508C91.1758 183.482 -91.2123 73.2696 74.14 67.5574C239.492 61.8453 515.071 129.763 521.987 208.108"
              stroke="white"
              stroke-width="3"
            />
          </svg>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <p class="intro">
            We're a team of individuals with unique skills based between Los Angeles and Milan, passionate about the future of stories and technology.
          </p>
        </div>
      </div>

      <div class="row team">
        <div
          class="team-block team-desk"
          v-for="(member, index) in team"
          :key="index"
        >
          <div class="team-member team-member-desk cursor-link">
            <div class="name">
              <h1>{{ member.name }} /</h1>
            </div>
            <div class="hideIpad">
              <p class="m-0">{{ member.role }}</p>
              <div class="team-social justify-end">
                <div v-if="member.link1.length > 0">
                  <a :href="member.link1" target="_blank">TW</a>
                </div>
                <div v-if="member.link2.length > 0">
                  <a :href="member.link2" target="_blank">IG</a>
                </div>
                <div v-if="member.link3.length > 0">
                  <a :href="member.link3" target="_blank">LI</a>
                </div>
                <div v-if="member.link4.length > 0">
                  <a :href="member.link4" target="_blank">LI</a>
                </div>
              </div>
            </div>

            <div class="btn-team" @click="open1()">
              <svg
                width="105"
                height="100"
                viewBox="0 0 105 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M74.6916 54.4618C68.1406 58.9405 62.7224 65.1722 58.5875 72.9848C56.3952 77.1265 54.8028 81.249 53.6486 84.9731V0H50.6699V0.0586407H50.6371V85.0317C49.4832 81.3076 47.8908 77.1849 45.6985 73.0431C41.5636 65.2308 36.1454 58.9988 29.5944 54.5204C21.4052 48.9215 11.4485 46.083 0 46.083V49.0597C10.8369 49.0597 20.2281 51.7235 27.9124 56.9774C34.0606 61.1804 39.1589 67.054 43.0657 74.435C49.7635 87.0895 50.6321 99.8723 50.6401 100L53.0791 99.9238L53.6459 99.9414C53.6539 99.8137 54.5225 87.0309 61.2203 74.3764C65.1271 66.9954 70.2254 61.1218 76.3733 56.9187C84.058 51.6649 93.4491 49.001 104.286 49.001V46.0243C92.8376 46.0243 82.8808 48.8632 74.6916 54.4618Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>

        <div
          class="team-block team-mobile"
          v-for="(member, index) in team"
          :key="index"
        >
          <div
            class="team-member"
            @click="toggleMember(index)"
            :class="{ teamblockmob: index === this.selectedTeamMember }"
          >
            <div class="name">
              <h1>{{ member.name }} /</h1>

              <div class="btn-team">
                <svg
                  :class="{ rotate: index == this.selectedTeamMember }"
                  width="105"
                  height="100"
                  viewBox="0 0 105 100"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M74.6916 54.4618C68.1406 58.9405 62.7224 65.1722 58.5875 72.9848C56.3952 77.1265 54.8028 81.249 53.6486 84.9731V0H50.6699V0.0586407H50.6371V85.0317C49.4832 81.3076 47.8908 77.1849 45.6985 73.0431C41.5636 65.2308 36.1454 58.9988 29.5944 54.5204C21.4052 48.9215 11.4485 46.083 0 46.083V49.0597C10.8369 49.0597 20.2281 51.7235 27.9124 56.9774C34.0606 61.1804 39.1589 67.054 43.0657 74.435C49.7635 87.0895 50.6321 99.8723 50.6401 100L53.0791 99.9238L53.6459 99.9414C53.6539 99.8137 54.5225 87.0309 61.2203 74.3764C65.1271 66.9954 70.2254 61.1218 76.3733 56.9187C84.058 51.6649 93.4491 49.001 104.286 49.001V46.0243C92.8376 46.0243 82.8808 48.8632 74.6916 54.4618Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            <div
              class="mobile-team-roles"
              v-if="index == this.selectedTeamMember"
            >
              <p class="m-0">{{ member.role }}</p>
              <div class="team-social justify-end">
                <div v-if="member.link1.length > 0">
                  <a :href="member.link1">TW</a>
                </div>
                <div v-if="member.link2.length > 0">
                  <a :href="member.link2">IG</a>
                </div>
                <div v-if="member.link3.length > 0">
                  <a :href="member.link3">LI</a>
                </div>
                <div v-if="member.link4.length > 0">
                  <a :href="member.link4">LI</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="team-block blank"></div>
        <div class="team-block seed">
          <h1>
            Part of SeedClub SC04 <span>Accelerator Cohort //</span>
            <span>
              <svg
                width="240"
                height="27"
                viewBox="0 0 240 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M125.565 0.479492L113.957 25.8704H118.478L130.087 0.479492H125.565Z"
                  fill="black"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M118.304 8.56596L122 0.479004H117.478L113.826 8.479L118.304 8.56596Z"
                  fill="black"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M85.3043 21.7836H92.4783C98.4348 21.7836 101.435 18.8271 101.435 13.0445C101.435 7.26185 98.3913 4.30532 92.4348 4.30532H85.2609L85.3043 21.7836ZM92.8261 25.7401C100.87 25.7401 105.913 21.001 105.913 13.0445C105.913 5.13141 100.826 0.348803 92.8261 0.348803H80.8261V25.6966H92.8261V25.7401ZM76.6957 21.7836V25.7401H54.2174V0.348803H76.3478V4.30532H58.6957V10.6966H74.1739V14.6532H58.6957V21.7836H76.6957ZM50.087 25.7401V21.7836H32.087V14.6097H47.5652V10.6532H32.087V4.30532H49.7391V0.348803H27.6087V25.6966L50.087 25.7401ZM12.1304 26.0879C19.2174 26.0879 23.0435 23.3923 23.0435 18.6097C23.0435 14.5662 20.1304 12.0445 14.3043 11.0879L10.2609 10.4358C7.17391 9.87054 5.86957 9.00098 5.86957 7.13141C5.86957 5.26185 7.95652 3.9575 11.6522 3.9575H12.2174C16 3.9575 18.3913 5.47924 18.8261 8.17489H23.5217C23.0435 3.13141 18.8696 0.000976562 11.9565 0.000976562C4.82609 0.000976562 1.3913 3.39228 1.3913 7.3488C1.3913 11.8271 3.82609 13.7401 9.56522 14.7401L13.5217 15.3488C17.087 15.9575 18.5652 17.001 18.5652 18.7836C18.5652 20.8705 16.6087 22.1314 12.3913 22.1314H11.8261C7.43478 22.1314 4.78261 20.4792 4.69565 17.3488H0C0.0434783 23.1314 4.65217 26.0879 12.1304 26.0879Z"
                  fill="black"
                />
                <mask
                  id="mask0_25_52"
                  style="mask-type: alpha"
                  maskUnits="userSpaceOnUse"
                  x="135"
                  y="0"
                  width="105"
                  height="27"
                >
                  <path d="M135.651 0H240V26.0878H135.651V0Z" fill="white" />
                </mask>
                <g mask="url(#mask0_25_52)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M221.739 21.7836H231.261C233.913 21.7836 235.522 20.4358 235.522 18.2184C235.522 16.001 233.913 14.8705 231.261 14.8705H221.739V21.7836ZM231 10.8705H221.739V4.30532H231C233.435 4.30532 234.913 5.52271 234.913 7.52271C234.913 9.69663 233.435 10.8705 231 10.8705ZM240 18.8271C240 23.001 236.435 25.6966 231.957 25.6966H217.261V0.348801H231.522C235.957 0.348801 239.391 2.9575 239.391 7.04445C239.391 8.30532 239 9.52271 238.304 10.5662C237.565 11.6097 236.565 12.3923 235.391 12.8271C238 13.3923 240 15.6531 240 18.8271ZM200.391 26.0879C207.783 26.0879 211.739 22.0445 211.739 15.7401V0.348801H207.304V15.4358C207.304 19.7836 205 22.1314 200.696 22.1314H200.13C195.826 22.1314 193.522 19.7836 193.522 15.4358V0.348801H189.043V15.6966C189.043 22.0879 192.87 26.0879 200.391 26.0879ZM187.652 21.7836V25.7401H165.957V0.348801H170.435V21.7401H187.652V21.7836ZM161.957 17.5227C161.13 22.5662 156.565 26.0879 149.565 26.0879C141 26.0879 135.652 20.6097 135.652 13.0445C135.565 5.65315 141.261 -0.0859814 149.565 0.000975136C156.739 0.000975136 161.217 3.87054 161.957 8.56619H157.261C156.609 5.65315 153.565 3.9575 149.87 3.9575H149.304C143.652 3.9575 140.13 7.9575 140.13 13.0445C140.13 18.1749 143.652 22.1314 149.304 22.1314H149.87C153.652 22.1314 156.522 20.5227 157.261 17.5227H161.957Z"
                    fill="black"
                  />
                </g>
              </svg>
            </span>
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Studio",

  data() {
    return {
      selectedTeamMember: -1,
      isHovering: false,
      team: [
        {
          name: "Alessandro Botteon",
          role: "Founder, ex Google & UNDERSCORE DISTRICT",
          link1: "https://twitter.com/alebarti1",
          link2: "",
          link3: "https://www.linkedin.com/in/alessandro-botteon-49731950/",
          link4: "",
        },
        {
          name: "Leonardo Manfrini",
          role: "COO & HEAD OF PARTNERSHIPS, EX EVERLI, DOCTOR IN PHILOSOPHY",
          link1: "https://twitter.com/leomanfrini",
          link2: "",
          link3: "https://www.linkedin.com/in/leonardo-manfrini-5a03436b/",
          link4: "",
        },
        {
          name: "Angelo Mennillo",
          role: "Head of Technology, ex Highway and Recipient, >15y Full Stack developer",
          link1: "",
          link2: "",
          link3: "https://www.linkedin.com/in/angelo-mennillo-6753bb20/",
          link4: "",
        },
        {
          name: "Sina Lessani Bahri",
          role: "Head of Analytics, 2x PhD in Data Science, ex Contents",
          link1: "",
          link2: "",
          link3: "https://www.linkedin.com/in/sina-lessani-bahri-b2367a76/",
          link4: "",
        },
        {
          name: "Ryan Liu",
          role: "Head of Performance, ex Booster Box",
          link1: "",
          link2: "",
          link3: "https://www.linkedin.com/in/ryanwhl/",
          link4: "",
        },
      ],
    };
  },

  methods: {
    toggleMember(memberIndex) {
      if (memberIndex == this.selectedTeamMember) {
        this.selectedTeamMember = -1;
      } else {
        this.selectedTeamMember = memberIndex;
      }
    },
  },
};
</script>

<style></style>
