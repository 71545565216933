<template>
  <div id="nav">
    <router-view />
  </div>
</template>

<script>
import AOS from "aos";

export default {
  name: "App",
  mounted() {
    setTimeout(function () {
      AOS.init({
        offset: 150, // offset (in px) from the original trigger point
        delay: 0, // values from 0 to 3000, with step 50ms
        mirror: true,
      });
      AOS.refresh();
    }, 1000);
  },
};

</script>
