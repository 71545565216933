<template>
  <div class="header-mobile" id="header-mobile">
    <div class="logos">
      <img src="../assets/img/logo.svg" draggable="false" />
    </div>
    <div class="text-end">
      <svg
        @click="openMenu()"
        id="open"
        v-if="!isMenuOpen"
        width="100%"
        height="100%"
        viewBox="0 0 47 25"
        version="1.1"
      >
        <title>5246D648-D23F-4660-B308-611BF6C0CF92</title>
        <g
          id="IMPSSBL_HP"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          stroke-linecap="round"
        >
          <g
            id="__mob_IMPSSBL_HP_V2"
            transform="translate(-305.000000, -117.000000)"
            stroke="#FFFFFF"
            stroke-width="2.5"
          >
            <g id="HEADER" transform="translate(0.000000, 94.000000)">
              <g id="menu" transform="translate(307.000000, 25.000000)">
                <path
                  d="M0,6.42600724 C6.49894431,4.55386789 20.2521138,0.00736970898 32.6795145,0.00113184358 C34.1380113,0.00035211041 41.147947,-0.0972404891 42.5683938,1.71485941 C43.7059583,3.16594284 42.290235,4.30357354 41.8018826,4.5374935 C39.3567093,5.71489059 33.7379014,6.63341627 32.0810196,6.92893514 C24.6646761,8.25214234 17.2076584,8.70048891 9.80338589,10.5687296 C5.86848845,11.5613299 17.6952235,11.4872553 21.6403551,12.0151346 C24.2067637,12.358997 27.6121098,12.9087089 30.1874404,13.5215791 C32.3722991,14.0416612 34.782309,14.3574531 36.912848,16.2085396 C37.1214671,16.3894377 37.367349,16.4892436 37.5119391,16.9718984 C37.5741312,17.1785277 37.4214063,17.5083548 37.3355969,17.6175175 C36.2717707,18.9680153 34.7167055,18.6670383 33.6059072,18.8034916 C31.0276899,19.1208431 28.4140468,18.7364346 25.843702,19.4397539 C24.9420476,19.6861496 25.5904726,20.398046 25.9560152,21"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <svg
        @click="closeMenu()"
        id="close-menu"
        v-if="isMenuOpen"
        width="35"
        height="35"
        viewBox="0 0 41 41"
        fill="none"
      >
        <path
          d="M39.6139 37.4584C39.7554 37.5999 39.8677 37.7679 39.9443 37.9528C40.0209 38.1378 40.0603 38.336 40.0603 38.5361C40.0603 38.7363 40.0209 38.9345 39.9443 39.1194C39.8677 39.3043 39.7554 39.4723 39.6139 39.6139C39.4723 39.7554 39.3043 39.8677 39.1194 39.9443C38.9345 40.0209 38.7363 40.0603 38.5361 40.0603C38.336 40.0603 38.1378 40.0209 37.9528 39.9443C37.7679 39.8677 37.5999 39.7554 37.4584 39.6139L20.0603 22.2415L2.66224 39.6139C2.3764 39.8997 1.98872 40.0603 1.58448 40.0603C1.18024 40.0603 0.792563 39.8997 0.506724 39.6139C0.220885 39.328 0.0603027 38.9404 0.0603027 38.5361C0.0603027 38.1319 0.220885 37.7442 0.506724 37.4584L17.8791 20.0603L0.506724 2.66224C0.220885 2.3764 0.0603027 1.98872 0.0603027 1.58448C0.0603027 1.18024 0.220885 0.792563 0.506724 0.506724C0.792563 0.220885 1.18024 0.0603027 1.58448 0.0603027C1.98872 0.0603027 2.3764 0.220885 2.66224 0.506724L20.0603 17.8791L37.4584 0.506724C37.7442 0.220885 38.1319 0.0603027 38.5361 0.0603027C38.9404 0.0603027 39.328 0.220885 39.6139 0.506724C39.8997 0.792563 40.0603 1.18024 40.0603 1.58448C40.0603 1.98872 39.8997 2.3764 39.6139 2.66224L22.2415 20.0603L39.6139 37.4584Z"
          fill="white"
        />
      </svg>
    </div>
    <Transition
      enter-active-class="fade-in-top"
      leave-active-class="fade-out-top"
    >
      <div class="menu-mobile" v-if="isMenuOpen">
        <div class="mob-col"></div>
        <div class="container-fluid p-0">
          <div class="row m-0">
            <a href="#about" click="closeMenu()">About</a>
          </div>
          <div class="row m-0">
            <a href="#about" @click="closeMenu()">Tech</a>
          </div>
          <div class="row m-0">
            <a href="#about" @click="closeMenu()">Stories</a>
          </div>
          <div class="row m-0">
            <a href="#portfolio" @click="closeMenu()">Portfolio</a>
          </div>
          <div class="row m-0">
            <a href="#accelerator" @click="closeMenu()">Accelerator</a>
          </div>

          <div class="row m-0">
            <a href="#contacts" @click="closeMenu()">Contact</a>
          </div>
          <div class="row m-0 socials">
            <div class="col-lg-6">
              <a href="https://twitter.com/Impssbl1" target="_blank">Twitter</a>
              <a href="https://www.linkedin.com/company/impssbl/" target="_blank">LINKEDIN</a>
              <a href="https://impssbl.medium.com/" target="_blank">Medium</a>
            </div>
            <div class="col-lg-6">
              <a href="https://www.instagram.com/impssblstudios/" target="_blank">Instagram</a>
              <a href="https://discord.gg/kZ8EwJRJ9y" target="_blank">Discord</a>
            </div>
          </div>
        </div>
        <div class="mob-col"></div>
      </div>
    </Transition>
  </div>
</template>

<script>
import "animate.css";

export default {
  name: "Header",
  data() {
    return {
      isMenuOpen: false,
    };
  },

  methods: {
    openMenu() {
      this.isMenuOpen = true;
      // document.getElementById("Path").classList.add("svg-elem-1");
      // document.getElementById("Path").style.display = "none";
      document.querySelector("body").style.overflowY = "hidden";
      document.querySelector("body").style.position = "fixed";
      document.querySelector("body").style.top = `-${this.scrollPosition}px`;
      document.querySelector("body").style.width = "100%";
    },
    closeMenu() {
      this.isMenuOpen = false;
      // document.getElementById("Path").classList.remove("svg-elem-1");
      // document.getElementById("Path").style.display = "inline";
      document.querySelector("body").style.overflowY = "scroll";
      document.querySelector("body").style.removeProperty("position");
      document.querySelector("body").style.removeProperty("top");
      document.querySelector("body").style.removeProperty("width");
      /* document.getElementById("open").style.display = "inline"; */
    },
  },
};
</script>

<style>

.menu-mobile {
  & .socials {
    & a {
      text-transform: uppercase;
    }
  }
}
</style>
