<template>
  <div class="tech" id="accelerator">
    <div class="container-fluid">
      <div class="projects-title">
        <h2>THE ACCELERATOR.</h2>
        <div class="tech-sign">
          <svg
            data-aos="lining"
            data-aos-delay="100"
            data-aos-duration="1000"
            width="379"
            height="136"
            viewBox="0 0 379 136"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M337.422 34.2848C299.449 22.9508 206.206 0.633782 137.013 2.06574C50.5201 3.85569 -2.21913 52.1843 149.67 109.463C301.559 166.741 449.229 113.042 337.422 57.5541C225.614 2.06574 23.0957 -14.0438 2 61.134"
              stroke="black"
              stroke-width="3"
            />
          </svg>
        </div>
      </div>

      <div class="row elenco m-0">
        <div class="col-lg-3"></div>
        <div class="col-lg-9 p-0">
          <div class="elenco-title">
            <div>1</div>
            <h2 class="anchor">SELECTION:<br /> <i class="thin">What are we looking for?</i></h2>
          </div>

          <p>
            We look for stories of different formats, even though in the first phases of our accelerator we focus mostly on novels and graphic novels that already have a digital distribution and revolve mostly around sci-fi, fantasy and historical genres.<br /> The most common criteria are: quality of the work, size of community, existing monetisation, adherence to current trends.
          </p>

          <div class="elenco-title">
            <div>2</div>
            <h2>ACCELERATION:<br /> <i class="thin">Your story, infinite readers.</i></h2>
          </div>
          <p>
            Once we negotiate a deal with the author, agent or publishing company, the acceleration starts. It involves a variety of tools, ranging from performance marketing (Google, Meta, Amazon Ads), expansion through a proprietary network of Booktokers and Influencers, website building and other tools. The author does not pay for a single thing: all of the acceleration is paid for by us, in a revenue share model. It’s a bet that we place on the stories in our portfolio.
          </p>

          <div class="elenco-title">
            <div>3</div>
            <h2>EXPANSION:<br /> <i class="thin">From book to film</i></h2>
          </div>

          <p>
            Once we proved the story-market fit at a digital level (in the publishing industry, both through proprietary e-commerce and Amazon as digital distribution), we work on a case-by-case basis to expand the existing format into multiple geographies, by taking care of its translation.
          </p>
          <p>
            Secondly, if we deem it relevant, we turn it into further formats by either making them in-house or through partners (audiobooks, podcasts, graphic novels, webtoons) or negotiating book-to-film rights, book-to-games for bigger formats.
          </p>

          <div class="blank-div bottom-align" id="dao">
            <div>
              <div class="link layers-anim white">
                <div class="movie">ACCELERATE YOUR STORY
                  <a class="movie-2 effect-movie" href="https://922dh6d8smp.typeform.com/to/jeHUDfKg" target="_blank">ACCELERATE YOUR STORY</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tech",
};
</script>

<style>
  .tech-sign {
    transform: translate(-1rem, 2.8rem)
  }



  .thin {
    font-weight: 300;
  }

  #dao.bottom-align {
    display: flex;
    align-items: center;
    padding: 4rem 2rem;

  }

</style>
