<template>
  <div class="projects">
    <img
      src="../assets/img/blob-projects.png"
      draggable="false"
      class="blob-projects"
    />
    <div class="container-fluid superstudio b-rx">
      <div class="projects-title">
        <h2>OUR PORTFOLIO.<br />Stories like you’ve never seen before.</h2>
        <div class="projects-sign">
          <svg
            data-aos="lining"
            width="522"
            height="154"
            viewBox="0 0 522 154"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M50.5539 61.1124C102.428 45.1954 230.308 11.434 326.839 3.75138C447.503 -5.85187 525.367 33.897 318.955 109.095C112.543 184.292 -98.066 151.796 52.6664 83.5775C203.399 15.3591 484.086 -26.7256 520.301 43.0902"
              stroke="white"
              stroke-width="3"
            />
          </svg>
        </div>
      </div>

      <div v-for="(tab, index) in tabs" :key="index">
        <div class="musess cursor-link" @click="toggleTab(index)">
          <div
            class="schedule-title d-flex flex-column flex-md-row align-items-lg-center"
          >
            <div
              :class="{
                'd-flex align-items-center justify-content-between': isMobile,
              }"
            >
              <div>
                <h2>{{ tab.title }}</h2>
                <div v-if="isMobile" class="jan22">
                  <h2>{{ tab.date }}</h2>
                </div>
              </div>

              <div v-if="isMobile" class="arrow">
                <img :src="tab.img" draggable="false" />
                <div class="align-self-end">
                  <svg
                    :class="{ rotate: tab.isOpen }"
                    id="pof-arrow"
                    viewBox="0 0 105 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M74.6916 54.4618C68.1406 58.9405 62.7224 65.1722 58.5875 72.9848C56.3952 77.1265 54.8028 81.249 53.6486 84.9731V0H50.6699V0.0586407H50.6371V85.0317C49.4832 81.3076 47.8908 77.1849 45.6985 73.0431C41.5636 65.2308 36.1454 58.9988 29.5944 54.5204C21.4052 48.9215 11.4485 46.083 0 46.083V49.0597C10.8369 49.0597 20.2281 51.7235 27.9124 56.9774C34.0606 61.1804 39.1589 67.054 43.0657 74.435C49.7635 87.0895 50.6321 99.8723 50.6401 100L53.0791 99.9238L53.6459 99.9414C53.6539 99.8137 54.5225 87.0309 61.2203 74.3764C65.1271 66.9954 70.2254 61.1218 76.3733 56.9187C84.058 51.6649 93.4491 49.001 104.286 49.001V46.0243C92.8376 46.0243 82.8808 48.8632 74.6916 54.4618Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div v-if="!isMobile" class="jan22 d-flex align-items-center">
              <h2>{{ tab.date }}</h2>
              <div class="arrow cursor-link">
                <img id="hoverPOS" :src="tab.img" draggable="false" />
                <svg
                  :class="{ rotate: tab.isOpen }"
                  id="pof-arrow"
                  viewBox="0 0 105 100"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M74.6916 54.4618C68.1406 58.9405 62.7224 65.1722 58.5875 72.9848C56.3952 77.1265 54.8028 81.249 53.6486 84.9731V0H50.6699V0.0586407H50.6371V85.0317C49.4832 81.3076 47.8908 77.1849 45.6985 73.0431C41.5636 65.2308 36.1454 58.9988 29.5944 54.5204C21.4052 48.9215 11.4485 46.083 0 46.083V49.0597C10.8369 49.0597 20.2281 51.7235 27.9124 56.9774C34.0606 61.1804 39.1589 67.054 43.0657 74.435C49.7635 87.0895 50.6321 99.8723 50.6401 100L53.0791 99.9238L53.6459 99.9414C53.6539 99.8137 54.5225 87.0309 61.2203 74.3764C65.1271 66.9954 70.2254 61.1218 76.3733 56.9187C84.058 51.6649 93.4491 49.001 104.286 49.001V46.0243C92.8376 46.0243 82.8808 48.8632 74.6916 54.4618Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <Transition
          enter-active-class="fade-in-top"
          leave-active-class="fade-out-top"
        >
          <div class="proof-info" v-if="tab.isOpen">
            <div class="row m-0">
              <div class="col-lg-4">
                <div class="token-border"></div>
                <div class="token">
                  <h4>{{ tab.imgTitle }}</h4>
                  <h4 v-html="tab.imgSubtitle"></h4>
                  <div class="token-img">
                    <img
                      :src="`${tab.img}`"
                      draggable="false"
                      v-if="tab.img.length > 0"
                    />
                  </div>
                  <div class="token-marquee">
                    <Vue3Marquee :clone="true" :duration="4">
                      <p>{{ tab.movingText }}&nbsp;</p>
                    </Vue3Marquee>
                  </div>
                </div>
              </div>
              <div class="col-lg-5">
                <p>
                  <span v-html="tab.txt1"></span>
                  <br />
                  <br />
                  <span v-html="tab.txt2"></span>
                </p>

                <div class="links">
                  <div class="proof-link layers-anim">
                    <div class="movie" v-if="tab.link1.length > 0">
                      {{ tab.link1 }}
                      <a
                        class="movie-2 effect-movie"
                        :href="tab.link0"
                        target="_blank"
                      >
                        {{ tab.link1 }}
                      </a>
                    </div>
                  </div>

                  <div class="social">
                    <div v-if="tab.link2.length > 0">
                      <a :href="tab.link2" target="_blank">TW</a>
                    </div>
                    <div v-if="tab.link3.length > 0">
                      <a :href="tab.link3" target="_blank">DS</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  </div>
  <div class="projects blank-div" id="tech">
    <!-- <div class="link layers-anim white">
      <div class="movie">VISIT IMPSSBLSTUDIOS
        <a class="movie-2 effect-movie" href="http://impssblstudios.com/" target="_blank">VISIT IMPSSBLSTUDIOS</a>
      </div>

    </div>
    <div>TO GET A LOOK AT ALL OUR STORIES.</div> -->

  </div>
</template>

<script>
import checkViewport from "@/mixins/checkViewport";
import { Vue3Marquee } from "vue3-marquee";
import "vue3-marquee/dist/style.css";

export default {
  name: "Projects",
  mixins: [checkViewport],
  components: {
    Vue3Marquee,
  },

  data() {
    return {
      isPOFopen: false,
      isMUSOpen: false,
      isUNTOpen: false,
      isHDOpen: false,
      isPNKOpen: false,

      tabs: [
        {
          isOpen: false,
          title: "OMQRS",
          date: "",
          imgTitle: "OMQRS",
          imgSubtitle: "One Million Quantum Random Stories.",
          img: "omqrs.png",
          movingText: "Upgrade your creativity",
          txt1: "A book made of one million random numbers obtained through a quantum supercomputer that you can use to generate an (almost) infinite supply of plotlines.",
          txt2: "<strong>Format:</strong><i> Hardcover Book, Non-Fiction</i><br /><strong>Class:</strong> <i>Original by Impssbl</i>",
          link0: "http://omqrs.com/",
          link1: "omqrs.com",
          link2: "",
          link3: "",
        },
        {
          isOpen: false,
          title: "LEAP: A QUANTUM NOVEL",
          date: "",
          imgTitle: "A QUANTUM NOVEL",
          imgSubtitle: "PROBABLY NOTHING.",
          img: "leap.png",
          movingText: "COMING SOON",
          txt1: "The most improbable novel you'll ever read.<br /><br /><strong>Format:</strong><i> Serialised novel</i><br /><strong>Class:</strong> <i>Original by Impssbl</i>",
          txt2: "COMING SOON",
          link0: "http://leapnovel.com/",
          link1: "leapnovel.com",
          link2: "",
          link3: "",
        },
        {
          isOpen: false,
          title: "FESTA: AN EROTIC PODCAST",
          date: "",
          imgTitle: "A LOT OF SEX",
          imgSubtitle: "AND A LOT OF PARTY",
          img: "festa.png",
          movingText: "COMING SOON",
          txt1: "An orally-told tale about a party gone (very) wrong.<br />Room temperature spike warning.",
          txt2: "<strong>Format:</strong> <i> Podcast, Fiction</i><br /><strong>Class:</strong> <i> Original by Impssbl</i><br /><br />COMING SOON",
          link0: "",
          link1: "",
          link2: "",
          link3: "",
        },
        {
          isOpen: false,
          title: "ODYSSEY IN THE SIXTIES",
          date: "",
          imgTitle: "ODYSSEY IN THE SIXTIES",
          imgSubtitle: "Ulysses’s journey (kinda) reimagined.",
          img: "odissey.png",
          movingText: "COMING SOON",
          txt1: "The Odyssey by Homer, set in the 1960s as a James Bond / B&W gentlemen’s trip around the Mediterranean Sea. Forgot shipwrecks and sirens: it’s all about Riva motorboat and scootering on the Amalfi coast.<br />Welcome to the Grand Tour of Ulysses.",
          txt2: "<strong>Format:</strong> <i>Hardcover Book, Fiction</i><br /><strong>Class:</strong> <i>Original by Impssbl</i>",
          link0: "",
          link1: "",
          link2: "",
          link3: "",
        },
        {
          isOpen: false,
          title: "IMPOSSIBLE JOURNALS",
          date: "",
          imgTitle: "Journals with a twist.",
          imgSubtitle: "A papertech brand.",
          img: "impossible-journals.png",
          movingText: "LIVE NOW",
          txt1: "This is not a single IP. It’s a brand. A brand of classic, timeless journals designed to supercharge your life, reach your goals and rewire your mind.<br />Proudly designed in Italy.",
          txt2: "<strong>Format:</strong> <i>Journals</i><br /><strong>Class:</strong> <i>Original by Impssbl</i>",
          link0: "https://impossiblejournals.com/",
          link1: "impossiblejournals.com",
          link2: "",
          link3: "",
        },
        {
          isOpen: false,
          title: "ZEROSTORY",
          date: "",
          imgTitle: "THE GREATEST STORY",
          imgSubtitle: "THAT WAS <i>NEVER</i> TOLD.",
          img: "zero-story.png",
          movingText: "COMING SOON",
          txt1: "What if all the stories of all the worlds cease to exist? What if only the mischievous ones, the anti-heroes, the sidekicks forgotten by history are left?<br /> Welcome to the biggest non-story of the world.",
          txt2: "<strong>Format:</strong> <i>Graphic Novel</i><br /><strong>Class:</strong> <i>Original by Impssbl</i><br /><br />COMING SOON",
          link0: "",
          link1: "",
          link2: "",
          link3: "",
        },
        {
          isOpen: false,
          title: "500 IMPOSSIBLE FILMS",
          date: "",
          imgTitle: "THE ULTIMATE LIST OF MOVIES",
          imgSubtitle: "That No One Heard About.",
          img: "impssbl-films.png",
          movingText: "COMING SOON",
          txt1: "A guidebook containing the reviews of 500 movies that are either unknown, not-finished, or really hard to find. Yet, they are gems.<br /> Welcome to the next-gen of your movie knowledge.",
          txt2: "<strong>Format:</strong> <i>Hardcover, Non-Fiction</i><br /> <strong>Class:</strong> <i>Original by Impssbl</i><br /><br />COMING SOON",
          link0: "",
          link1: "",
          link2: "",
          link3: "",
        },


      ],
    };
  },
  methods: {
    toggleTab(tabIndex) {
      const app = this;
      app.tabs[tabIndex].isOpen = !app.tabs[tabIndex].isOpen;
      if (app.tabs[tabIndex].isOpen) {
        app.tabs.forEach((tab, index) => {
          if (index !== tabIndex) {
            tab.isOpen = false;
          }
        });
      }
    },
    // follow() {
    //   const app = this;
    //   app.active = !app.active;
    //   console.log("follow attivo?", app.active);
    //   if (app.active) {
    //     window.$(document).mousemove(function (e) {
    //       window.$("#hoverPOS").css({ left: e.pageX, top: e.pageY });
    //     });
    //   }
    // },
  },
};
</script>

<style>
.projects-sign {
  transform: translate(-2rem, -4.3rem)
}

.projects {

  &.blank-div {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem 2rem;
    font-size: 1.25rem;
    flex-flow: column;
    gap: 2rem;

    @media screen and (min-width: 1024px) {
      flex-flow: row;
    }




  }

  & .bottom-align {
      display: flex;
      align-items: center;
      padding: 4rem 2rem;

  }
}

</style>
