<template>
  <footer class="hideIpad">
    <div class="container-fluid p-0">
      <div class="row justify-content-center">
        <div class="col-7">
          <div class="row" style="max-width: 100%; margin: 0">
            <div class="col cursor-link">
              <div class="col-bg"></div>
              <a href="https://twitter.com/Impssbl1" target="_blank"><div class="link">Twitter</div></a>
            </div>
            <div class="col cursor-link">
              <div class="col-bg"></div>
              <a href="https://www.linkedin.com/company/impssbl/" target="_blank"><div class="link">Linkedin</div></a>
            </div>
            <div class="col cursor-link">
              <div class="col-bg"></div>
              <a href="https://impssbl.medium.com/" target="_blank"><div class="link">Medium</div></a>
            </div>
            <div class="col cursor-link">
              <div class="col-bg"></div>
              <a href="https://www.instagram.com/impssblstudios/" target="_blank"><div class="link">Instagram</div></a>
            </div>
            <div class="col cursor-link">
              <div class="col-bg"></div>
              <a href="https://discord.gg/kZ8EwJRJ9y" target="_blank"><div class="link">Discord</div></a>
            </div>
          </div>
        </div>
        <div class="col-2"></div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style></style>
