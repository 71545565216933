import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import jQuery from "jquery";
global.$ = jQuery;


// import VueScrollTo from "vue-scrollto";
// const app = createApp({});
// app.use(VueScrollTo, {
//   container: "body",
//   duration: 5000,
//   easing: "ease",
//   offset: 500,
//   force: true,
//   cancelable: true,
//   onStart: false,
//   onDone: false,
//   onCancel: false,
//   x: false,
//   y: true,
// });

import "./themes/style.scss";
import "./themes/resp.scss";
import "./themes/animation.scss";
import "@/assets/fonts/stylesheet.css";

import CursorFx from "@luxdamore/vue-cursor-fx";
import "@luxdamore/vue-cursor-fx/dist/CursorFx.css";

import Vue3Marquee from "vue3-marquee";
import "vue3-marquee/dist/style.css";


createApp(App).use(router, CursorFx, Vue3Marquee).mount("#app");
